<template>
  <LoginContainer>
        <div>
          <h2 class="tw-mt-6 tw-text-5xl tw-font-extralight tw-text-orange-500">
            risky.<span class="lg:tw-text-black tw-text-white">live</span>
          </h2>
        </div>

        <div class="tw-mt-8">
          <div>
            <div>
              <div class="tw-mt-1">
                <LoginForm></LoginForm>
              </div>
              <div class="tw-mt-3">
                <div>
                  <router-link
                    :to="{name: 'Reset password'}"
                    class="tw-w-full tw-inline-flex tw-justify-center tw-py-3 tw-px-4 tw-rounded-md lg:tw-bg-gray-50 tw-bg-black-200 lg:hover:tw-bg-gray-100  tw-text-sm tw-leading-5 tw-font-medium lg:tw-text-gray-500 tw-text-white hover:tw-text-gray-400 focus:tw-outline-none focus:tw-border-gray-300 focus:tw-shadow-outline-gray tw-transition tw-duration-150 tw-ease-in-out"
                  >
                    <span class="lg:tw-text-gray-500 tw-text-white hover:tw-text-gray-400">Having trouble logging in?</span>
                    <!-- <icon :icon="['fal', 'external-link-alt']" class="ml-2"></icon> -->
                  </router-link>
                </div>
              </div>
              <div class="tw-mt-8 tw-text-center">
                <p class="tw-mt-2 tw-text-sm lg:tw-text-gray-600 tw-text-white max-w">
                  Don't have an account?
                  <a
                    href="https://www.riskfacilitator.com/risky"
                    target="_blank"
                    class="tw-font-medium tw-text-orange-500 hover:tw-text-orange-600"
                  >
                    <span class="tw-text-orange-500 hover:tw-text-orange-600">Sign up now</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
  </LoginContainer>
</template>

<script>
import LoginContainer from "@/views/Auth/LoginContainer";
import LoginForm from "@/components/Auth/LoginForm";

export default {
  name: "Login",
  components: {
    LoginContainer,
    LoginForm,
  },
};
</script>